exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-us-tsx": () => import("./../../../src/pages/about-us.tsx" /* webpackChunkName: "component---src-pages-about-us-tsx" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-book-a-call-tsx": () => import("./../../../src/pages/book-a-call.tsx" /* webpackChunkName: "component---src-pages-book-a-call-tsx" */),
  "component---src-pages-careers-tsx": () => import("./../../../src/pages/careers.tsx" /* webpackChunkName: "component---src-pages-careers-tsx" */),
  "component---src-pages-case-studies-index-tsx": () => import("./../../../src/pages/case-studies/index.tsx" /* webpackChunkName: "component---src-pages-case-studies-index-tsx" */),
  "component---src-pages-contact-us-tsx": () => import("./../../../src/pages/contact-us.tsx" /* webpackChunkName: "component---src-pages-contact-us-tsx" */),
  "component---src-pages-hire-developers-tsx": () => import("./../../../src/pages/hire-developers.tsx" /* webpackChunkName: "component---src-pages-hire-developers-tsx" */),
  "component---src-pages-hiring-in-latin-america-for-hr-leaders-tsx": () => import("./../../../src/pages/hiring-in-latin-america-for-HR-Leaders.tsx" /* webpackChunkName: "component---src-pages-hiring-in-latin-america-for-hr-leaders-tsx" */),
  "component---src-pages-increase-roi-hiring-nearshore-latin-america-tsx": () => import("./../../../src/pages/increase-roi-hiring-nearshore-latin-america.tsx" /* webpackChunkName: "component---src-pages-increase-roi-hiring-nearshore-latin-america-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-latin-america-salary-guide-pdf-tsx": () => import("./../../../src/pages/latin-america-salary-guide-pdf.tsx" /* webpackChunkName: "component---src-pages-latin-america-salary-guide-pdf-tsx" */),
  "component---src-pages-latin-america-salary-guide-thank-you-tsx": () => import("./../../../src/pages/latin-america-salary-guide-thank-you.tsx" /* webpackChunkName: "component---src-pages-latin-america-salary-guide-thank-you-tsx" */),
  "component---src-pages-latin-america-tech-salary-guide-2024-tsx": () => import("./../../../src/pages/latin-america-tech-salary-guide-2024.tsx" /* webpackChunkName: "component---src-pages-latin-america-tech-salary-guide-2024-tsx" */),
  "component---src-pages-nearshore-staffing-for-technology-leaders-tsx": () => import("./../../../src/pages/nearshore-staffing-for-technology-leaders.tsx" /* webpackChunkName: "component---src-pages-nearshore-staffing-for-technology-leaders-tsx" */),
  "component---src-pages-nearshore-tech-staffing-for-ce-os-tsx": () => import("./../../../src/pages/nearshore-tech-staffing-for-CEOs.tsx" /* webpackChunkName: "component---src-pages-nearshore-tech-staffing-for-ce-os-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-schedule-a-call-tsx": () => import("./../../../src/pages/schedule-a-call.tsx" /* webpackChunkName: "component---src-pages-schedule-a-call-tsx" */),
  "component---src-pages-services-tsx": () => import("./../../../src/pages/services.tsx" /* webpackChunkName: "component---src-pages-services-tsx" */),
  "component---src-pages-techcon-socal-tsx": () => import("./../../../src/pages/techcon-socal.tsx" /* webpackChunkName: "component---src-pages-techcon-socal-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-case-studies-post-tsx": () => import("./../../../src/templates/case-studies-post.tsx" /* webpackChunkName: "component---src-templates-case-studies-post-tsx" */),
  "component---src-templates-hire-remote-tsx": () => import("./../../../src/templates/hire-remote.tsx" /* webpackChunkName: "component---src-templates-hire-remote-tsx" */)
}

